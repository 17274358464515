import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { OrganizationListItem } from '@mp/kernel/organizations/domain';

import { OrganizationsActions } from './organizations.actions';

export const organizationsFeatureKey = 'mpk-core-organizations';

type OrganizationsEntityState = EntityState<OrganizationListItem>;

const organizationsEntityAdapter = createEntityAdapter<OrganizationListItem>({
  selectId: ({ organizationId }: OrganizationListItem) => organizationId,
});

export interface OrganizationsState {
  organizations: OrganizationsEntityState;
  organizationsLoaded: boolean;
}

export const initialState: OrganizationsState = {
  organizations: organizationsEntityAdapter.getInitialState(),
  organizationsLoaded: true,
};

export const organizationsReducer = createReducer(
  initialState,
  on(
    OrganizationsActions.fetchOrganizations,
    (state): OrganizationsState => ({
      ...state,
      organizations: organizationsEntityAdapter.getInitialState(),
      organizationsLoaded: false,
    }),
  ),
  on(
    OrganizationsActions.fetchOrganizationsSuccess,
    (state, { organizationsPageResponse }): OrganizationsState => ({
      ...state,
      organizations: organizationsEntityAdapter.setAll(organizationsPageResponse.data, state.organizations),
      organizationsLoaded: true,
    }),
  ),
  on(
    OrganizationsActions.fetchOrganizationsError,
    (state): OrganizationsState => ({
      ...state,
      organizationsLoaded: true,
    }),
  ),
  on(
    OrganizationsActions.upsertOrganizationToList,
    (state, { organization }): OrganizationsState => ({
      ...state,
      organizations: organizationsEntityAdapter.upsertOne(organization, state.organizations),
    }),
  ),
);

export const { selectAll: selectAllOrganizations } = organizationsEntityAdapter.getSelectors();
