import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@core/shared/domain';
import { QueryParams } from '@core/shared/util';
import { OrganizationListItem } from '@mp/kernel/organizations/domain';

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
  private readonly baseUrl = '/api/m/kernel/organizations';

  constructor(private readonly http: HttpClient) {}

  fetchOrganizations(searchTerm?: string): Observable<PageResponse<OrganizationListItem>> {
    const params = QueryParams.build().param('searchTerm', searchTerm).withoutPagination().toHttpParams();

    return this.http.get<PageResponse<OrganizationListItem>>(this.baseUrl, { params });
  }
}
