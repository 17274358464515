import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrganizationsEffects, OrganizationsFacade, organizationsFeatureKey, organizationsReducer } from '../store';

export function provideOrganizationsDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(organizationsFeatureKey, organizationsReducer)),
    importProvidersFrom(EffectsModule.forFeature([OrganizationsEffects])),
    makeEnvironmentProviders([OrganizationsFacade]),
  ];
}
