import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrganizationsState, organizationsFeatureKey, selectAllOrganizations } from './organizations.reducer';

export const selectOrganizationsState = createFeatureSelector<OrganizationsState>(organizationsFeatureKey);

export const selectOrganizationsLoaded = createSelector(selectOrganizationsState, (state) => state.organizationsLoaded);

export const selectOrganizations = createSelector(selectOrganizationsState, (state) =>
  selectAllOrganizations(state.organizations),
);
