import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { OrganizationListItem } from '@mp/kernel/organizations/domain';

import { OrganizationsActions } from './organizations.actions';
import { selectOrganizations, selectOrganizationsLoaded } from './organizations.selectors';

@Injectable()
export class OrganizationsFacade {
  readonly organizations: Signal<OrganizationListItem[]> = this.store$.selectSignal(selectOrganizations);

  readonly organizationsLoaded: Signal<boolean> = this.store$.selectSignal(selectOrganizationsLoaded);

  constructor(private readonly store$: Store) {}

  fetchOrganizations(searchTerm?: string): void {
    this.store$.dispatch(OrganizationsActions.fetchOrganizations({ searchTerm }));
  }

  upsertOrganizationToList(organization: OrganizationListItem): void {
    this.store$.dispatch(OrganizationsActions.upsertOrganizationToList({ organization }));
  }
}
